import { ref, computed, watch } from "vue";
import connect from "@/plugins/connect";
import config from '@/config';
let axios;
const STORAGE_DEFAULT = { user: null, message: null, token: null, prefix: null, source: config.connections[0].source };
const connection = ref();
const state = ref(null);
watch(() => state, (val) => {
    window.localStorage.setItem(config.store_name, JSON.stringify(val.value));
}, { deep: true });
state.value = JSON.parse(window.localStorage.getItem(config.store_name) || JSON.stringify(STORAGE_DEFAULT));
function setSource(source) {
    state.value.source = source;
    connection.value = config.connections.filter((c) => c.source == source)[0] || config.connections[0];
    connection.value.token = state.value.token;
    axios = new connect(connection.value);
}
setSource(state.value.source);
export default function authService(dataSource) {
    axios = new connect(connection.value);
    const user = computed(() => state.value.user);
    const source = computed(() => state.value.source);
    const organisation = computed(() => {
        if (Array.isArray(state.value.user.organisation))
            return state.value.user.organisation[0];
        if (Array.isArray(state.value.user['#HOSTS']))
            return state.value.user['#HOSTS'][0];
        return state.value.user.organisation;
    });
    const team = computed(() => {
        return organisation.value._contacts || [];
    });
    const message = computed(() => state.value.message);
    const token = ref(state.value.token);
    const prefix = ref(state.value.prefix);
    const authenticated = computed(() => {
        if (!state.value.user || !state.value.token)
            return false;
        return true;
    });
    function hasRole(role) {
        let type = 'lead';
        //if (!authenticated.value) return logout()
        if (state.value.user._feed == 'SUPPLIERS')
            type = 'supplier';
        if (state.value.user._feed == 'ROOT')
            type = 'root';
        if (role == type || role.includes(type))
            return true;
        if (!state.value.user.role)
            return false;
        try {
            return state.value.user.role == role || role.includes(state.value.user.role) || state.value.user.role.includes(role);
        }
        catch (e) {
            return false;
        }
    }
    function reset() {
        state.value = JSON.parse(JSON.stringify(STORAGE_DEFAULT));
    }
    async function logout() {
        let valid = true;
        window.localStorage.clear();
        let path = 'auth/logout';
        axios = null;
        if (prefix.value)
            path = 'auth/' + prefix.value + '/logout';
        try {
            if (state.value.token)
                await axios.get(path);
        }
        catch (e) {
            valid = false;
        }
        reset();
        return valid;
    }
    //if (!axios.getConnection()) return { authenticated, hasRole, user, organisation, message, token, prefix, logout, axios, setSource}
    async function authenticate(credentials) {
        const response = await axios.post('auth/login', credentials);
        if (response.status == 'OK') {
            setUser(response.user, response.token, response.prefix);
        }
        else {
            state.value.message = response.message;
        }
        return state.value.user;
    }
    function setUser(user, token, prefix) {
        if (!user)
            return false;
        const settings = { ...(user.props || {}) };
        if (user.settings) {
            user.settings.map((set) => settings[set.name] = set.value || set.amount);
            delete user.settings;
        }
        if (user.tags) {
            const tags = {};
            user.tags.map((tag) => {
                if (!tags[tag.type])
                    tags[tag.type] = [];
                tags[tag.type].push(tag.name);
            });
            user.tags = tags;
        }
        delete user.props;
        user._settings = settings;
        state.value.user = user;
        if (token)
            state.value.token = token;
        if (prefix)
            state.value.prefix = prefix;
    }
    async function userinfo(path = 'auth/me') {
        if (!authenticated.value)
            return false;
        try {
            const response = await axios.get(path);
            if (response.status == 'OK') {
                setUser(response.user, response.token, response.prefix);
            }
        }
        catch (e) {
            reset();
        }
        return state.value.user;
    }
    return { connection, source, authenticate, authenticated, hasRole, logout, user, organisation, team, message, userinfo, token, prefix, axios, loading: axios.loading, setSource, state };
}
