import { ref } from "vue";
import config from "@/config";
import axios from "axios";
class Connect {
    base;
    key;
    token;
    connection;
    loading = ref(false);
    constructor(connection) {
        this.connection = connection;
        this.base = connection.api || config.server + '/' + connection.source + '/';
        this.key = connection.key;
        this.token = connection.token;
        this.loading.value = false;
    }
    setBase(url) {
        this.base = url;
    }
    async get(path, params, headers) {
        return await this.request("GET", path, params, null, headers);
    }
    async post(path, body, headers) {
        return await this.request("POST", path, null, body, headers);
    }
    async put(path, body) {
        return await this.request("PUT", path, null, body);
    }
    async delete(path, body) {
        return await this.request("DELETE", path, null, body);
    }
    async form(path, files) {
        const url = path && path.includes(':') ? path : this.base + path;
        const headers = {
            'Content-Type': 'multipart/form-data',
            "x-api-key": this.key
        };
        if (this.token)
            headers['Authorization'] = 'Bearer ' + this.token;
        const resp = await axios.post(url, files, {
            headers
        });
        return resp.data;
        /*  return await this.request("POST", path, null, null, {
            "content-type": "multipart/form-data",
          } , files);*/
    }
    async getr(url) {
        const resp = await axios.get(url);
        return resp.data;
        /*  return await this.request("POST", path, null, null, {
            "content-type": "multipart/form-data",
          } , files);*/
    }
    /*async upload(url, name, blob, path){
      const options = {
        url,
        name: name,
        filePath: path,
        blob
        //fileDirectory: FilesystemDirectory.Downloads,
      };
      return await Http.uploadFile();
    }*/
    async request(method, path, params, body, headers, data) {
        if (!path)
            return false;
        this.loading.value = true;
        const defaults = {
            //baseURL: config.api.url,
            headers: {
                "content-type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "x-api-key": this.key
            },
        };
        if (this.token)
            defaults.headers['Authorization'] = 'Bearer ' + this.token;
        let url = path && path.includes(':') ? path : this.base + path;
        const options = {
            method,
            headers: Object.assign(defaults.headers, headers || {}),
        };
        if (params)
            url += '?' + queryParams(params);
        if (body)
            options.body = JSON.stringify(body);
        if (data)
            options.body = data;
        //if (headers) options.headers = headers
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.statusCode == 401 && ["err_invalid_token", "err_no_token"].includes(data.error))
                this.logMeOUt();
            this.loading.value = false;
            return data;
        }
        catch (e) {
            console.log(url, options, e.message);
            this.logMeOUt();
            this.loading.value = false;
            return { status: 'ERROR', message: e.message };
        }
    }
    logMeOUt() {
        // const { logout} = authService();
        //logout()
        document.location = "/logout";
    }
}
export default Connect;
function queryParams(params) {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}
