const server = (process.env.NODE_ENV == "development") ? 'http://' + document.location.hostname + ':3000' : 'https://test.openorder.nl';
const config = {
    store_name: 'vue-store1',
    development: (process.env.NODE_ENV == "development"),
    public: {
        api: server + '/api/',
        key: 'root'
    },
    connections: [
        { source: "api", name: 'Eetplein', key: "TEST_0KFWsQyZtUKlWwsyiK64jECM0Ls_Q-G-", api: server + '/api/', bg: '/img/bg-login.jpg', icon: "/img/eetplein.png", redir: '/tabs' },
        { source: "nkc", name: "Selectiehulp", key: "LB-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9", api: server + '/nkc/', logo: '/img/nkclogonew_slogan.png', bg: '/img/bg-login.jpg', class: 'nkc', icon: "/logo.png", redir: '/dossier' },
        { source: "abcrm", name: "Max Music", key: 'ABCrm-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9', api: server + '/abcrm/', store: "Max Music", logo: '/img/Logo_maXMusic.svg', bg: '/img/bg_maxmusic.png', class: 'max-music', icon: "/img/Logo_maXMusic.png", redir: '/abcrm' },
        { source: "odoo2", name: "Smartman", key: 'ODOO-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9', server: 'smartman.odoo.com', port: 80, database: 'smartman', bg: '/img/odooimage.png', class: 'odoo', icon: "/img/odoo_logo.png", redir: '/odoo' },
        { source: "odoo", name: "Service center", key: 'ODOO-08yHhdd0KFWsQyZtUKlWwsyiK64jEo7ygasdASd9', server: 'servicecenter.odoo.com', port: 80, database: 'servicecenter', bg: '/img/odooimage.png', class: 'odoo', icon: "/img/odoo_logo.png", redir: '/odoo' }
    ],
    server,
    /*live: {
      key: "0KFWsQyZtUKlWwsyiK64jECM0Ls_Q-G-",
      api: server + '/api/',//'https://test.openorder.nl/api/',
      redir: '/tabs'
    },*/
    tb: {
        api: server + '/api/' //'https://api.openorder.nl/api'
    },
    service: {
        zipcode: 'https://nominatim.openstreetmap.org/search?format=json&countrycodes=NL&polygon=0&addressdetails=1',
        ccvimport: 'https://api.openorder.nl/api',
        kvk: 'https://api.overheid.io/openkvk?ovio-api-key=96e5d363a7eea7f352cb71002233959ae1c5d38c4edc419233740f9edf934dbc&fields[0]=dossiernummer&queryfields[0]=dossiernummer&fields[1]=locatie&queryfields[1]=handelsnaam&fields[2]=straat&fields[3]=postcode&fields[4]=plaats&query=',
        kvk2: 'https://api.kvk.nl/api/v2/search/companies?apikey=l7xx3d3fcf7669d241a2b2fabfed976618d6&q='
    },
    firebase: {
        config: {
            apiKey: "AIzaSyCgtKBaUtw_g9P_9LOwAaO1TFs89ZbBu64",
            authDomain: "spaces-c9a15.firebaseapp.com",
            databaseURL: "https://spaces-c9a15.firebaseio.com",
            projectId: "spaces-c9a15",
            storageBucket: "spaces-c9a15.appspot.com",
            messagingSenderId: "743473985149",
            appId: "1:743473985149:web:54a49d9eb85e2a8cae2314",
        }
    }
};
export default config;
