import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../views/tabs/TabsPage.vue';
import authService from '../plugins/authService';
const { connection, authenticated, logout, setSource } = authService();
const routes = [
    {
        name: 'home',
        path: '',
        redirect: '/login',
        meta: {
            title: 'login',
            requiresAuth: true
        }
    },
    { name: 'logout',
        path: '/logout',
        component: () => import('@/views/LoginForm.vue'),
        meta: { title: "Logout" }
    },
    { name: 'nkcLogin',
        path: '/nkc/login',
        component: () => import('@/views/LoginForm.vue'),
        meta: { title: 'login' }
    },
    {
        path: '/dossier',
        name: 'Dossierlist',
        component: () => import('@/views/dossier/Index.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Home',
                redirect: '/dossier/list',
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'list',
                name: 'Dossiers',
                component: () => import('@/views/dossier/List.vue'),
                meta: {
                    nav: true
                }
            },
            {
                path: 'suppliers',
                name: 'Leveranciers',
                component: () => import('@/views/dossier/Suppliers.vue'),
                meta: {
                    nav: true
                }
            },
            {
                path: 'testimonials',
                name: 'Testimonials',
                component: () => import('@/views/dossier/Test.vue'),
                meta: {
                    nav: true
                }
            },
            {
                path: 'import',
                name: 'Importeren',
                component: () => import('@/views/dossier/Import.vue'),
                meta: {
                    nav: true
                }
            },
            {
                path: 'team',
                name: 'Team',
                component: () => import('@/views/dossier/Team.vue'),
                meta: {
                    nav: true
                }
            },
            {
                path: 'questions',
                name: 'Vragenlijst',
                component: () => import('@/views/dossier/Questions.vue'),
                meta: {
                    nav: true
                }
            },
            {
                path: 'organisation/:key',
                name: 'Organisation',
                props: route => ({ params: route.params }),
                component: () => import('@/views/dossier/Organisation.vue'),
                meta: {
                    nav: true
                }
            },
        ]
    },
    {
        name: 'Dossier',
        path: '/dossier/:key',
        props: route => ({ params: route.params }),
        component: () => import('@/views/dossier/Dossier.vue'),
        meta: {
            back: '/dossier/list', requiresAuth: true
        }
    },
    {
        path: '/requirements/:key',
        name: 'Eisen- en wensenlijst',
        props: route => ({ params: route.params }),
        component: () => import('@/views/dossier/Requirements.vue'),
        meta: {
            requiresAuth: true
        }
    },
    { name: 'login',
        path: '/login',
        component: () => import('@/views/LoginForm.vue'),
        meta: {
            title: 'login',
        }
    },
    {
        path: '/tabs/',
        component: TabsPage,
        name: 'Tabs',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Tabsoverzicht',
                redirect: '/tabs/tab1'
            },
            {
                path: 'tab1',
                name: 'Tab 1',
                component: () => import('@/views/tabs/Tab1Page.vue')
            },
            {
                path: 'tab2',
                name: 'Tab 2',
                component: () => import('@/views/tabs/Tab2Page.vue')
            },
            {
                path: 'tab3',
                name: 'Tab 3',
                component: () => import('@/views/tabs/Tab3Page.vue')
            },
            {
                path: 'tab4',
                name: 'Tab 4',
                component: () => import('@/views/tabs/Tab4Page.vue')
            }
        ]
    },
    { name: 'abcrmLogin',
        path: '/project/login',
        component: () => import('@/views/LoginForm.vue'),
        meta: { title: "Login" }
    },
    {
        name: 'Projecten',
        path: '/abcrm',
        component: () => import('@/views/project/Index.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/project',
                name: 'Kalenderredir',
                redirect: '/project/calendar',
            },
            {
                path: '/project/list',
                name: 'Projectlijst',
                component: () => import('@/views/project/List.vue'),
                meta: {
                    nav: true,
                    load: 'list'
                }
            },
            {
                path: '/project/list/:status',
                name: 'Statuslijst',
                component: () => import('@/views/project/List.vue'),
                meta: {
                    nav: true,
                    load: 'list'
                }
            },
            {
                path: '/project/calendar',
                name: 'Kalender',
                component: () => import('@/views/project/Calendar.vue'),
                meta: {
                    nav: true,
                    load: 'feed'
                }
            },
            {
                path: '/project/:index',
                name: 'Projectinfo',
                component: () => import('@/views/project/Project.vue'),
                meta: {
                    nav: true
                }
            },
            {
                path: '/project/profile',
                name: "Profiel",
                component: () => import('@/views/project/Profile.vue'),
                meta: {
                    nav: true,
                    load: 'profile'
                }
            }
        ]
    },
    {
        name: 'Project',
        path: '/project/:project',
        component: () => import('@/views/project/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'payment',
        path: '/order/:index',
        component: () => import('@/views/order/Payment.vue')
    },
    {
        name: 'payments',
        path: '/payments',
        component: () => import('@/views/order/Profile.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'Producten',
        path: '/odoo',
        component: () => import('@/views/product/Index.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Overzicht',
                redirect: '/product'
            },
            {
                path: '/product',
                name: 'Odoo Home',
                redirect: '/product/list',
            },
            {
                path: '/product/list',
                name: 'Productlijst',
                component: () => import('@/views/product/Products.vue'),
                meta: {
                    nav: true,
                    load: 'list'
                }
            },
            {
                path: '/porder/list',
                name: 'Inkooporderlijst',
                component: () => import('@/views/product/Porders.vue'),
                meta: {
                    nav: true,
                    load: 'list'
                }
            },
            {
                path: '/sorder/list',
                name: 'Verkooporderlijst',
                component: () => import('@/views/product/Sorders.vue'),
                meta: {
                    nav: true,
                    load: 'list'
                }
            },
            {
                path: '/cabinet/list',
                name: 'Kastenbeheer',
                component: () => import('@/views/product/Cabinets.vue'),
                meta: {
                    nav: true,
                    load: 'list'
                }
            },
            {
                path: '/scanner',
                name: 'Scanner',
                component: () => import('@/views/product/Scanner.vue'),
                meta: {
                    nav: true,
                    load: 'scanner'
                }
            }
        ]
    },
];
/*{
  name: 'dossier',
  path: '/dossier/:key',
  props: route => ({ params: route.params }),
  component: () => import('@/views/Dossier.vue'),
  meta: {
    requiresAuth: true
  }
},
{
  name: 'testimonial',
  path: '/testimonial',
  props: route => ({ params: route.params }),
  component: () => import('@/views/Testimonial.vue'),
  meta: {
    requiresAuth: true
  }
},
{
  name: 'home',
  path: '/',
  redirect: '/dossier',
  meta: {
    requiresAuth: true
  }
},
{name: 'login',
  path: '/login',
  component: () => import('@/views/LoginForm.vue')
},
{name: 'register',
  path: '/register',
  component: () => import('@/views/Register.vue')
},
{
  name: 'profile',
  path: '/profile',
  component: () => import('@/views/Profile.vue'),
  meta: {
    requiresAuth: true
  }
},

{
  name: 'orderitem',
  path: '/order/:index',
  component: () => import('@/views/Payment.vue')
},
{
  path: '/tabs/',
  component: TabsPage,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: '',
      redirect: '/tabs/tab1'
    },
    {
      path: 'tab1',
      component: () => import('@/views/tabs/Tab1Page.vue')
    },
    {
      path: 'tab2',
      component: () => import('@/views/tabs/Tab2Page.vue')
    },
    {
      path: 'tab3',
      component: () => import('@/views/tabs/Tab3Page.vue')
    },
    {
      path: 'tab4',
      component: () => import('@/views/tabs/Tab4Page.vue')
    }
  ]
}
]*/
//
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from) => {
    if (to.name == 'logout') {
        await logout();
        return {
            name: 'login'
        };
    }
    if (to.meta && to.meta.requiresAuth && !authenticated.value) {
        const q = to.query ? to.query : {};
        q.redir = to.path;
        return {
            name: 'login',
            query: q
        };
    }
});
const loadedCSS = {};
router.afterEach((to, from) => {
    document.title = `${connection.value.name} - ${to.meta.title || to.name}`;
    const link = document.querySelector("[rel='icon']");
    if (link)
        link.setAttribute('href', connection.value.icon);
    if (loadedCSS[connection.value.source])
        return false;
    import(`@/theme/${connection.value.source}.css`);
    loadedCSS[connection.value.source] = true;
});
export default router;
