import { ref, computed, watch } from 'vue';
import { loadingController, toastController, isPlatform, menuController, alertController } from "@ionic/vue";
const store_name = 'lb-settings';
const STORAGE_DEFAULT = {};
const settings = ref(JSON.parse(window.localStorage.getItem(store_name) || JSON.stringify(STORAGE_DEFAULT)));
watch(() => settings, (val) => {
    //console.log('state watch', val.value)
    window.localStorage.setItem(store_name, JSON.stringify(val.value));
}, { deep: true });
const darkMode = computed({
    get: () => {
        return settings.value.modus == 'dark';
    },
    set: (value) => {
        settings.value.modus = value ? 'dark' : 'light';
        document.body.classList.toggle('dark', value);
        document.body.classList.toggle('light', !value);
        //console.log(value, window.matchMedia('(prefers-color-scheme: dark)').matches, window.localStorage.getItem('lb-interface-modus'),darkMode.value)
    }
});
darkMode.value = darkMode.value ? true : false;
if (window.matchMedia('(prefers-color-scheme: dark)').matches && settings.value.modus !== 'light')
    darkMode.value = true;
const loading = ref(true);
const message = ref('loading');
const duration = ref(3000);
const isMobile = computed(() => {
    //return false
    if (screen.width > 720)
        return false;
    return isPlatform('mobile');
});
const mini = ref(false);
const right = ref(false);
const scrollTop = ref(0);
const mouseTop = ref(0);
let popupVisible = false;
let controler;
function logScrolling(event) {
    scrollTop.value = event.detail.scrollTop;
}
export default function interfaceControler(initialValue) {
    loading.value = initialValue;
    async function showLoading(m) {
        if (m)
            message.value = m;
        loading.value = true;
        controler = await loadingController
            .create({
            duration: duration.value,
            message: message.value,
            translucent: true,
            cssClass: 'custom-class custom-loading',
            backdropDismiss: true
        });
        await controler.present();
        setTimeout(function () {
            hideLoading();
        }, duration.value);
    }
    function hideLoading() {
        if (!controler)
            return false;
        controler.dismiss();
        controler.value = false;
        loading.value = false;
    }
    function toCurrency(value) {
        if (!value)
            return null;
        if (typeof value === "string")
            return parseFloat(value.replace(',', '.'));
        if (typeof value !== "number")
            return value;
        const formatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
        return formatter.format(value);
    }
    function scrollToBottom(target, timeout = 200) {
        const elem = document.getElementById(target);
        if (elem && elem.scrollToBottom) {
            setTimeout(() => elem.scrollToBottom(timeout), timeout);
        }
        else {
            console.log('not found or valid', target);
        }
    }
    async function showConfirm(header, message, handler, inputs = null) {
        const opts = {
            cssClass: 'my-confirm-class',
            header: header,
            message: message,
            buttons: [
                {
                    text: 'Annuleren',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: () => {
                        handler(true);
                        popupVisible = false;
                    }
                },
                {
                    text: 'OK',
                    id: 'confirm-button',
                    handler: (e) => {
                        handler(e);
                        popupVisible = false;
                    }
                },
            ],
        };
        if (inputs)
            opts.inputs = inputs;
        const alert = await alertController
            .create(opts);
        popupVisible = false;
        return await alert.present();
    }
    async function prompt(title, subtitle, text, next) {
        const alert = await alertController
            .create({
            cssClass: 'my-prompt-class',
            header: title,
            subHeader: subtitle,
            inputs: [
                {
                    name: "description",
                    type: 'textarea',
                    placeholder: 'Omschrijving',
                    value: text
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: blah => {
                        console.log('Confirm Cancel:', blah);
                    },
                },
                {
                    text: 'OK',
                    id: 'confirm-button',
                    handler: next,
                },
            ],
        });
        await alert.present();
    }
    async function showForm(header, inputs, handler, cancel = null) {
        const alert = await alertController
            .create({
            cssClass: 'my-form-class',
            header,
            inputs,
            buttons: [
                {
                    text: 'Annuleren',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: cancel,
                },
                {
                    text: 'Opslaan',
                    handler
                },
            ],
        });
        return alert.present();
    }
    function scrollToElement(id, timeout, target = 'dossier-content') {
        const win = document.getElementById(target);
        const element = document.getElementById(id);
        if (element)
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            }, timeout);
        /*const yOffset = -70;
        console.log(element)
        if (element) {
            
            const y = element.getBoundingClientRect().top + win.pageYOffset + yOffset;
      
            scrollToPoint(y,target)
            //const win:any = document.getElementById(target);
            //if (win) win.scrollToPoint(0, y, 400)
        }*/
    }
    function scrollToPoint(y, target = 'dossier-content') {
        const win = document.getElementById(target);
        if (win)
            win.scrollTo(0, y, 400);
    }
    async function openToast(text, options = { duration: 2000, color: 'primary' }) {
        options = Object.assign({ duration: 2000 }, options);
        const toast = await toastController
            .create(Object.assign({
            message: text
        }, options));
        return toast.present();
    }
    function openPanel(index = null || '', open = true) {
        if (open) {
            menuController.enable(true, index);
            return menuController.open(index);
        }
        return menuController.close(index);
    }
    return {
        openToast,
        openPanel,
        showLoading,
        hideLoading,
        loading,
        toCurrency,
        scrollToBottom,
        isPlatform,
        isMobile,
        scrollToElement, scrollToPoint,
        mini,
        right,
        logScrolling,
        mouseTop,
        scrollTop,
        showConfirm,
        showForm,
        darkMode,
        settings,
        prompt
    };
}
