import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_user_menu = _resolveComponent("user-menu");
    const _component_ion_header = _resolveComponent("ion-header");
    const _component_ion_icon = _resolveComponent("ion-icon");
    const _component_ion_label = _resolveComponent("ion-label");
    const _component_ion_item = _resolveComponent("ion-item");
    const _component_ion_list = _resolveComponent("ion-list");
    const _component_ion_content = _resolveComponent("ion-content");
    const _component_ion_menu = _resolveComponent("ion-menu");
    const _component_ion_router_outlet = _resolveComponent("ion-router-outlet");
    const _component_ion_tab_button = _resolveComponent("ion-tab-button");
    const _component_ion_tab_bar = _resolveComponent("ion-tab-bar");
    const _component_ion_tabs = _resolveComponent("ion-tabs");
    const _component_ion_page = _resolveComponent("ion-page");
    const _component_ion_split_pane = _resolveComponent("ion-split-pane");
    return (_openBlock(), _createBlock(_component_ion_split_pane, { "content-id": "main" }, {
        default: _withCtx(() => [
            _createVNode(_component_ion_menu, {
                side: "start",
                "menu-id": "first",
                "content-id": "main",
                class: "borderless"
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_ion_header, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_user_menu)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_ion_content, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_ion_list, { lines: "none" }, {
                                default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                                        return (_openBlock(), _createBlock(_component_ion_item, {
                                            button: "",
                                            routerLink: tab.path,
                                            key: i
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, {
                                                    icon: tab.icon,
                                                    slot: "start"
                                                }, null, 8, ["icon"]),
                                                _createVNode(_component_ion_label, {
                                                    textContent: _toDisplayString(tab.title)
                                                }, null, 8, ["textContent"])
                                            ]),
                                            _: 2
                                        }, 1032, ["routerLink"]));
                                    }), 128))
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }),
            _createVNode(_component_ion_page, { id: "main" }, {
                default: _withCtx(() => [
                    _createVNode(_component_ion_tabs, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_ion_router_outlet),
                            (_ctx.isMobile)
                                ? (_openBlock(), _createBlock(_component_ion_tab_bar, {
                                    key: 0,
                                    slot: "bottom"
                                }, {
                                    default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                                            return (_openBlock(), _createBlock(_component_ion_tab_button, {
                                                tab: `tab${i}`,
                                                href: tab.path,
                                                key: i,
                                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.closeMenu()))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                        icon: tab.icon
                                                    }, null, 8, ["icon"]),
                                                    _createVNode(_component_ion_label, {
                                                        textContent: _toDisplayString(tab.title)
                                                    }, null, 8, ["textContent"])
                                                ]),
                                                _: 2
                                            }, 1032, ["tab", "href"]));
                                        }), 128))
                                    ]),
                                    _: 1
                                }))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
